var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRole === 4 || _vm.currentRole === 5)?_c('div',{attrs:{"id":"center-management"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-10 pt-5"},[_c('h2',{staticClass:"mb-10"},[_vm._v("Center management")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.centerList,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","fixed-header":"","height":"65vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){return undefined}},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.viewDetail(item.locationID)}}},[_vm._v(_vm._s(item.location))])]}},{key:"item.tel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tel)+" ")]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"size":"18","color":"primary"},on:{"click":function($event){return _vm.viewDetail(item.locationID)}}},[_vm._v("visibility")])]}}],null,true)})],1)])],1),_c('div',{staticClass:"w-100 pl-10 pr-10",attrs:{"color":"#fff"}},[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"md":"4"}},[(_vm.centerList.length != 1)?_c('h3',[_vm._v("Total: "+_vm._s(_vm.centerList.length)+" centers")]):_c('h3',[_vm._v("Total: "+_vm._s(_vm.centerList.length)+" center")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7,"elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }